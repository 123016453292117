import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
} from "@angular/core";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { CustomizerService } from "../services/customizer.service";
import { ThemeService } from "../services/theme.service";
import { AppInitializerService } from "app/core/initializer/services/app-initializer.service";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-customizer",
  templateUrl: "./customizer.component.html",
  styleUrls: ["./customizer.component.scss"],
})
export class CustomizerComponent implements OnInit, OnDestroy {
  @ViewChild("customizer") customizer: ElementRef;
  color: string = "#000";

  defaultImage:string = "assets/img/smalllogo.png"

  selectedImage:string

  size: string;
  isBgImageDisplay: boolean = true;
  isOpen = true;
  public config: any = {};
  layoutSub: Subscription;

  entityForm: FormGroup;

  constructor(
    private renderer: Renderer2,
    private configService: ConfigService,
    public customizerService: CustomizerService,
    public themeService:ThemeService,
    private cdr:ChangeDetectorRef,
    private initializerService:AppInitializerService,
    private fb:FormBuilder
  ) {
    this.config = this.configService.templateConf;
    this.isOpen = !this.config.layout.customizer.hidden;

    if (this.config.layout.sidebar.size) {
      this.size = this.config.layout.sidebar.size;
    }
    
  

   
  }

  @Output() directionEvent = new EventEmitter<Object>();
  ngOnInit() {
    this.entityForm = this.fb.group({
      // Define your form controls here
      // For example, if you want to capture color and font selections
      color: ['#000'], // Initialize with default values if needed
      font: this.fb.array([
        {key:'font',value:'Times New Roman , serif' ,view:'Times New Roman'},
        {key:'font',value:'Arial , sans-serif',view:'Arial'},
        {key:'font',value:'Montserrat , sans-serif',view:'Montserrat'},
        {key:'font',value:'Roboto Mono , monospace',view:'Roboto Mono'},
        {key:'font',value:'feather , sans-serif',view:'feather'},
        {key:'font',value:'Georgia , serif',view:'Georgia'},
        {key:'font',value:'Courier New , monospace',view:'Courier New'}
      ]),
      name:['Mobile First Finance']
    });
    
      // Subscribe to form changes if needed
      this.entityForm.valueChanges.subscribe(value => {
        // Do something with the form values
        console.log(value)
      });
  }

  getControlValue(name):string{
   return (this.entityForm.get(name) as FormControl<string>).value
  }

  get fontArray() : FormArray {
    return this.entityForm.get("font") as FormArray
  }

  get colorControlValue():string{
    return this.entityForm.get('color').value
  }

 

  changeSidebarWidth(value) {
    this.size = value;
    this.customizerService.changeSidebarWidth(value);
  }

  toggleCustomizer() {
    if (this.isOpen) {
      this.renderer.removeClass(this.customizer.nativeElement, "open");
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.customizer.nativeElement, "open");
      this.isOpen = true;
    }
  }

  closeCustomizer() {
    this.renderer.removeClass(this.customizer.nativeElement, "open");
    this.isOpen = false;
  }

  bgImageDisplay(e) {
    if (e.target.checked) {
      this.isBgImageDisplay = true;
    } else {
      this.isBgImageDisplay = false;
    }
    //emit event to FUll Layout
    this.customizerService.bgImageDisplay(e);
  }

  public onChangeColor(color: string): void {
    this.color = color;
  }


  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        console.log(event);
        const logo = event.target.result
        // document.getElementById('appFavicon').setAttribute('href', logo);
        this.themeService.logo = logo;
        this.initializerService.appData.next({logoLink:logo,faviconIcon:logo})
        this.cdr.detectChanges();
      }
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }
}
