import { Routes, RouterModule } from "@angular/router";
import { SessionGuard } from "../../shared/session-guard/session.guard";

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "",
    // canActivate:[SessionGuard],
    children: [
      {
        path: "client",
        loadChildren: () =>
          import("../../modules/profiles/profiles.module").then(
            (m) => m.ProfilesModule
          ),
        title: "Clients",
      },
      {
        path: "contract-studio",
        loadChildren: () =>
          import("../../modules/contract-studio/contract-studio.module").then(
            (m) => m.ContractStudioModule
          ),
        title: "Contract Studio",
      },
      {
        path: "legal-agreements",
        loadChildren: () =>
          import("../../modules/legal-agreement/legal-agreement.module").then(
            (m) => m.LegalAgreementModule
          ),
        title: "Legal agreements",
      },
      {
        path: "tokenomics",
        loadChildren: () =>
          import("../../modules/tokenomics/tokenomics.module").then(
            (m) => m.TokenomicsModule
          ),
        title: "Tokenomics dashboard",
      },
      {
        path: "tokenization",
        loadChildren: () =>
          import("../../tokenization/tokenization.module").then(
            (m) => m.TokenizationModule
          ),
        title: "Tokenization suite",
      },
      {
        path: "account",
        loadChildren: () =>
          import("../../modules/account/account.module").then(
            (m) => m.AccountModule
          ),
        title: "Account",
      },
      {
        path: "document",
        loadChildren: () =>
          import("../../modules/document-storage/document-storage.module").then(
            (m) => m.DocumentStorageModule
          ),
        title: "Document storage",
      },
      {
        path: "tokens",
        loadChildren: () =>
          import("../../modules/tokens/tokens.module").then(
            (m) => m.TokensModule
          ),
        title: "Document storage",
      },
      {
        path: "settings",
        loadChildren: () =>
          import("../../modules/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
        title: "Settings",
      },
      {
        path: "customer-dashboard",
        loadChildren: () =>
          import(
            "../../modules/customer-dashboard/customer-dashboard.module"
          ).then((m) => m.CustomerDashboardModule),
        title: "Customer Dashboard",
      },
      {
        path: "welcome-page",
        loadChildren: () =>
          import("../../modules/welcome-page/welcome-page.module").then(
            (m) => m.WelcomePageModule
          ),
        title: "Welcome Page",
      },
      {
        path: "change-password",
        loadComponent: () =>
          import(
            "../../modules/change-password/change-password.component"
          ).then((m) => m.ChangePasswordComponent),
        title: "Change password",
      },
    ],
  },
];
