import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ThemeService } from "app/shared/services/theme.service";
import { BehaviorSubject } from "rxjs";
import { filter, map } from "rxjs/operators";

export interface AppData {
  themeColor?: string;
  logoLink?: string;
  name?: string;
  entityName?: string;
  faviconIcon?: string;
  font?: string;
}

@Injectable()
export class AppInitializerService {
  private holdData: AppData;
  public appData = new BehaviorSubject<AppData | null>(null);
  readonly appData$ = this.appData.asObservable().pipe(
    filter((data) => !!data),
    map((data) => {
      return {
        themeColor: data?.themeColor ?? this.holdData?.themeColor,
        logoLink: data?.logoLink ?? this.holdData?.logoLink,
        name: data?.name ?? this.holdData?.name,
        faviconIcon: data?.faviconIcon ?? this.holdData?.faviconIcon,
        font: data?.font ?? this.holdData?.font,
        entityName: data?.entityName ?? this.holdData?.entityName,
      };
    })
  );
  constructor(
    private httpClient: HttpClient,
    private themeService: ThemeService
  ) {}

  fetchAppData() {
    this.getRootConfig().subscribe((res) => {
      const appInstance = res["data"]["rootData"].find(
        (app) => app.url === "dev.mobilefirstfinance.com"
      );
      const { themeColor, name, logoLink, font, entityName } = appInstance;
      console.log(entityName);
      const appData = {
        themeColor,
        logoLink,
        name,
        entityName,
        faviconIcon: logoLink,
        font,
      };
      this.holdData = { ...appData };
      this.themeService.logo = logoLink;
      this.themeService.themeColor = themeColor;
      this.themeService.applyThemeColor(themeColor || "#000");
      this.appData.next(appData);
    });
  }

  getRootConfig() {
    const mffUrl = `https://dev.mobilefirstfinance.com`;
    return this.httpClient
      .get(`${mffUrl}:5455/root/entity`, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => {
        return res;
      });
  }
}
