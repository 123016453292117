import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { environment as env } from "environments/environment";

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor() {}

  /**
   * Intercepts an HTTP request and appends the base URL for relative URLs.
   * Excludes certain static asset requests from URL modification.
   *
   * @param {HttpRequest<any>} request - The outgoing HTTP request.
   * @param {HttpHandler} next - The next interceptor in the chain.
   * @returns {Observable<HttpEvent<any>>} - The observable for the HTTP event.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = request.url;

    // Ignore static asset requests
    if (this.isStaticAsset(url)) {
      return next.handle(request);
    }

    // Add base URL for relative URLs (without 'http://' or 'https://')
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      request = this.addBaseUrl(request);
    }

    return next.handle(request);
  }

  /**
   * Adds the appropriate base URL to the outgoing HTTP request.
   * Determines the base URL based on the request path and appends it to the request URL.
   *
   * @private
   * @param {HttpRequest<any>} request - The original HTTP request.
   * @returns {HttpRequest<any>} - The modified HTTP request with the base URL.
   */
  private addBaseUrl(request: HttpRequest<any>): HttpRequest<any> {
    const baseUrl = this.getBaseUrlForRequest(request.url);
    const updatedUrl = `${baseUrl}${request.url}`;
    return request.clone({ url: updatedUrl });
  }

  /**
   * Determines the appropriate base URL based on the request URL.
   * If the request URL contains 'realms', the Keycloak URL is used. Otherwise, the default base URL is used.
   *
   * @private
   * @param {string} url - The request URL.
   * @returns {string} - The base URL to be appended to the request URL.
   */
  private getBaseUrlForRequest(url: string): string {
    if (url.includes("realms")) {
      return env.keycloakUrl;
    }
    // Default base URL, appending the port if it's specified in the environment.
    return env.port ? `${env.mffURL}:${env.port}` : env.mffURL;
  }

  /**
   * Determines if the request URL refers to a static asset.
   * Static assets like configuration files, contract templates, or i18n files are excluded from URL modification.
   *
   * @private
   * @param {string} url - The request URL.
   * @returns {boolean} - Returns true if the request is for a static asset, otherwise false.
   */
  private isStaticAsset(url: string): boolean {
    return (
      url.startsWith("/assets/default-block-xml") ||
      url.startsWith("/assets/default-block-json") ||
      url.startsWith("/assets/data/contract-templates") ||
      url.startsWith("/assets/i18n")
    );
  }
}
