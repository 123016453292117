import { RouteInfo } from "../vertical-menu/vertical-menu.metadata";

export const HROUTES: RouteInfo[] = [
  {
    path: "/customer-dashboard",
    title: "Customer Dashboard",
    icon: "ft-home",
    class: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/welcome-page",
    title: "Welcome Page",
    icon: "ft-home",
    class: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/contract-studio",
    title: "Contract Studio",
    icon: "ft-grid",
    class: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/legal-agreements",
    title: "Legal agreements",
    icon: "ft-file",
    class: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/client/list-of-clients",
    title: "Clients",
    icon: "ft-users",
    class: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/account/account-list",
    title: "Account",
    icon: "ft-briefcase",
    class: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/tokenomics/tokenomic-dashboard",
    title: "Tokenomics",
    icon: "ft-trending-up",
    class: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/tokens/token-list",
    title: "DEX",
    icon: "ft-circle",
    class: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/document/document-storage",
    title: "Document Storage",
    icon: "icon-docs",
    class: "",
    isExternalLink: false,
    submenu: [],
  },
];
