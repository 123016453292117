import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from "@agm/core";

import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { DragulaService } from "ng2-dragula";
import { NgxSpinnerModule } from "ngx-spinner";
import { StoreModule } from "@ngrx/store";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
// import * as fromApp from "./store/app.reducer";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { WINDOW_PROVIDERS } from "./shared/services/window.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { EditProfileComponent } from "./modules/profiles/components/edit-profile/edit-profile.component";
import { ProfilesComponent } from "./modules/profiles/profiles.component";
import { CreateProfileComponent } from "./modules/profiles/components/create-profile/create-profile.component";

import { MatPaginatorModule } from "@angular/material/paginator";

import { BrowserModule } from "@angular/platform-browser";
import { Ng2SmartTableModule } from "ng2-smart-table";

import { CustomTitleStrategyService } from "./shared/services/custom-title-strategy.service";
import { Router, RouterOutlet, TitleStrategy } from "@angular/router";

import { Ng2TelInputModule } from "ng2-tel-input";

import { ThemeService } from "./shared/services/theme.service";
import { CoreModule } from "./core/core.module";

import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { initializeKeycloak } from "./core/utility/app.init-keycloak";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    ProfilesComponent,
    EditProfileComponent,
    CreateProfileComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    // StoreModule.forRoot(fromApp.appReducer),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    AppRoutingModule,
    Ng2SmartTableModule,
    Ng2TelInputModule,
    SharedModule,
    HttpClientModule,
    MatPaginatorModule,
    ToastrModule.forRoot(),
    NgbModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCERobClkCv1U4mDijGm1FShKva_nxsGJY",
    }),
    PerfectScrollbarModule,
  ],
  providers: [
    RouterOutlet,
    DragulaService,
    ThemeService,
    { provide: TitleStrategy, useClass: CustomTitleStrategyService },
    KeycloakService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeKeycloak,
    //   multi: true,
    //   deps: [KeycloakService,Router]
    // },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
