import { InjectionToken, NgModule, RendererFactory2 } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import { InitializerModule } from "./initializer/initializer.module";
import { AuthService } from "./auth/auth.service";
import { AuthGuard } from "./auth/auth-guard.service";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { ErrorHandlerInterceptor } from "./interceptors/error-handler.interceptor";
import { LoaderInterceptor } from "./interceptors/loader-interceptor";
import { FooterComponent } from "./footer/footer.component";
import { ApiPrefixInterceptor } from "./interceptors/api-prefix.interceptor";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api"; // Import MessageService
import { ChatbotScriptService } from "./services/chatbot-script.service";

// export function chatbotScriptFactory(): ChatbotScriptService {
//   return new ChatbotScriptService(RendererFactory2);
// }

// Create an Injection Token
export const CHATBOT_SCRIPT = new InjectionToken<ChatbotScriptService>(
  "ChatbotScriptService"
);

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    InitializerModule,
    ToastModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    MessageService,
    {
      provide: CHATBOT_SCRIPT,
      useFactory: () => new ChatbotScriptService(),
      deps: [RendererFactory2],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoggingInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorHandlerInterceptor,
    //   multi: true,
    // },
  ],
  exports: [InitializerModule, FooterComponent, ToastModule],
})
export class CoreModule {}
