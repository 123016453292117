import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import * as Chartist from "chartist";
import { ChartType, ChartEvent } from "ng-chartist";
import { BehaviorSubject, throwError, of } from "rxjs";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "app/app.service";

import {
  environment as env,
  environment,
} from "../../../environments/environment";
import { HttpService } from "app/core/services/http.service";
import { AuthService } from "app/core/auth/auth.service";
import { map } from "rxjs/operators";

export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Injectable({
  providedIn: "root",
})
export class LoginService {
  public listOfWorkflows = [];
  public modalOption: NgbModalOptions;

  public access_token: any;
  public username: string;
  public port: any;
  public url: any;
  public flag: any;

  public userId: string;

  public allBranchId: any;
  public map = new Map();
  public productsData = [];
  public otp2FA: string;
  public loginUser = {};
  public hierarchy: any;

  public rootUserID: string;
  public logoutToast = new BehaviorSubject("");
  public loginProcessing: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private appService: AppService,
    private httpService: HttpService,
    private authService: AuthService
  ) {
    this.modalOption = {
      backdrop: "static",
      keyboard: false,
    };
  }

  logInWithUsernameAndPassword(loginPayload) {
    const loginUrl = `/LoginService/login`;
    return this.httpClient
      .post<any>(`${loginUrl}`, loginPayload)
      .map((res) => {
        return res;
      })
      .catch(this.errorHandler);
  }

  logInWithUsernameAndPasswordUsingKeycloak(payload) {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      // 'X-Handle-Error-Locally': 'true' // Custom header to indicate local error handling
    });

    const body = new HttpParams()
      .set("grant_type", "password")
      .set("client_id", env.clientId)
      .set("client_secret", env.clientSecret)
      .set("username", payload.userName)
      .set("password", payload.password)
      .set("scope", "openid profile email");

    return this.httpService.httpWithoutInterceptor
      .post<any>(
        `${env.keycloakUrl}/realms/${env.realm}/protocol/openid-connect/token`,
        body,
        { headers }
      )
      .map((res) => {
        return res;
      })
      .catch(this.errorHandler);
  }

  OauthDetails() {
    return this.httpClient
      .get(`/UserService/oauthDetails`, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => res);
  }

  public access_tokenMethod() {
    return this.access_token;
  }

  public portnumber() {
    return this.port;
  }

  // getProductList() {
  //   return this.http
  //     .get(
  //       this.appService.validateURL +
  //         "/products?productID=&access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }

  public urlNumber() {
    return env.protocol + this.url;
  }

  changeTableTheme() {
    var title = document.querySelectorAll("ng2-st-column-title");
    var action = document.querySelectorAll("tbody");
    var buttons = document.querySelectorAll("button");
    var paginationButtons = document.querySelectorAll(".pagination");

    if (Array.from(title).length > 0) {
      Array.from(title).forEach((ele) => {
        ele.getElementsByTagName("a")[0].style.color =
          this.appService.themeColor;
        ele.getElementsByTagName("a")[0].classList.add("hoverEffect");
      });
    }

    if (Array.from(buttons).length > 0) {
      Array.from(buttons).forEach((ele) => {
        ele.style["background-color"] = this.appService.themeColor;
      });
    }

    if (Array.from(paginationButtons).length > 0) {
      Array.from(paginationButtons).forEach((ele) => {
        Array.from(ele.querySelectorAll("button")).forEach((page) => {
          page.style.color = "white";
        });
      });
    }
  }

  public flags() {
    return this.flag;
  }

  changeLang(locale) {
    return this.httpClient.get("../../assets/i18n/" + locale + ".json");
  }

  targetLang(url, translateObject) {
    return this.httpClient.post(url, translateObject);
  }

  errorHandler(error: Response) {
    if (error.status == 500) {
      this.loginProcessing = false;
      alert("Invalid RootUser/UserName/Password");
    }
    return throwError(error || " server error ");
  }
}
