// Angular Core Imports
import { Injectable, Injector } from '@angular/core';

// Angular HTTP Client Imports
import { HttpClient, HttpHandler, HttpRequest, HttpEvent, HttpBackend } from '@angular/common/http';

// RxJS Imports
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private httpClient: HttpClient;
  public httpWithoutInterceptor: HttpClient;

  /**
   * Constructor to inject necessary dependencies
   * @param injector - Injector service to get HttpHandler
   * @param httpBackend - HttpBackend to create HttpClient without interceptors
   */
  constructor(private injector: Injector, private httpBackend: HttpBackend) {
    const handler = injector.get(HttpHandler);
    this.httpClient = new HttpClient(handler);
    this.httpWithoutInterceptor = new HttpClient(this.httpBackend);
  }

  /**
   * Makes an HTTP request using HttpClient with interceptors
   * @param req - The HTTP request to be made
   * @returns Observable of the HTTP event
   */
  request<T>(req: HttpRequest<any>): Observable<HttpEvent<T>> {
    return this.httpClient.request<T>(req);
  }
}

