import { Injectable, inject } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  // private isLoading = new BehaviorSubject<boolean>(false);
  // public isLoading$ = this.isLoading.asObservable();
  private spinnerService = inject(NgxSpinnerService);
  public loaderMessage: string = "";
  public loaderType: string = "ball-spin-clockwise";

  public setLoadingMessage(
    message: string = "Loading...",
    loaderType: string = "ball-spin-clockwise"
  ) {
    this.loaderMessage = message;
    this.loaderType = loaderType;
  }

  public get loadingMessage(): string {
    return this.loaderMessage;
  }

  showLoader() {
    // this.isLoading.next(true);
    // ball-clip-rotate-multiple
    this.spinnerService.show();
  }

  hideLoader() {
    // this.isLoading.next(false);
    this.spinnerService.hide();
  }
}
