import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { environment } from "../environments/environment";

// ng build --prod --aot false --build-optimizer false
// ng build --prod --aot true --build-optimizer true
// ng serve --port 4444 --watch=false

@Injectable({
  providedIn: "root",
})
export class AppService {
  /**
   * Default app theme color
   */
  themeColor: string = "#4ABDAC";
  public userDetails: any;

  public readonly mffURL = environment.mffURL;
  public readonly protocol = environment.protocol;
  public readonly rootUser = environment.rootUser;

  private readonly mffBaseURL: string = environment.mffURL;
  private readonly port5055: string = ":5055";

  public colorTheme(): {} {
    return {
      color: this.themeColor,
    };
  }

  public bgTheme(): {} {
    return {
      "background-color": `${this.themeColor} !important;`,
    };
  }
}
