<nav
  class="navbar navbar-expand-lg navbar-light header-navbar {{
    transparentBGClass
  }}"
  [ngClass]="{
    'navbar-brand-center': menuPosition === 'Top',
    'navbar-static':
      menuPosition === 'Side' && config?.layout.navbar.type === 'Static',
    'navbar-sticky':
      menuPosition === 'Side' && config?.layout.navbar.type === 'Fixed',
    'fixed-top': isSmallScreen && menuPosition === 'Top'
  }"
>
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div
        class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse"
        (click)="toggleSidebar()"
      >
        <i class="ft-menu font-medium-3"></i>
      </div>
      <!-- <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a
            class="nav-link apptogglefullscreen"
            id="navbar-fullscreen"
            href="javascript:;"
            appToggleFullscreen
            (click)="ToggleClass()"
            ><i class=" {{ toggleClass }} font-medium-3"></i
          ></a>
        </li>

        <li class="nav-item nav-search">
          <a
            class="nav-link nav-link-search"
            id="navbar-search"
            href="javascript:"
            (click)="toggleSearchOpenClass(true)"
            ><i class="ft-search font-medium-3"></i
          ></a>
          <div class="search-input {{ searchOpenClass }}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input
              [formControl]="control"
              #search
              class="input"
              type="text"
              placeholder="Search..."
              tabindex="0"
              autofocus
              data-search="template-search"
              [appAutocomplete]="autocomplete"
              (keyup.enter)="onEnter()"
              (keydown.esc)="onEscEvent()"
              (keyup)="onSearchKey($event)"
            />
            <div
              class="search-input-close"
              (click)="toggleSearchOpenClass(false)"
            >
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent>
                <ng-container
                  *ngIf="listItems | filter : control.value as result"
                >
                  <app-option
                    #searchResults
                    *ngFor="let option of result"
                    [url]="option.url"
                    [value]="option.name"
                    (click)="redirectTo(option.url)"
                    (mouseenter)="removeActiveClass()"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between w-100"
                    >
                      <div
                        class="d-flex align-items-center justify-content-start"
                      >
                        <i class="{{ option.icon }} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length"
                    >No results found.</app-option
                  >
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>
      </ul> -->
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo">
                <a
                  style="white-space: nowrap; font-size: 0.8rem"
                  class="logo-text"
                  [routerLink]="['/']"
                  *ngIf="config?.layout.menuPosition === 'Top'"
                >
                  <ng-container
                    *ngIf="initializerService.appData$ | async as logoImg"
                  >
                    <div class="logo-img">
                      <img class="logo-img" alt="" [src]="logoImg?.logoLink" />
                    </div>
                    <span class="text">{{ logoImg.entityName }}</span>
                  </ng-container>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="navbar-logo">
        <ul class="navbar-nav">
          <li class="nav-item">
            <div class="logo">
              <a
                style="white-space: nowrap; font-size: 0.8rem"
                class="logo-text"
                [routerLink]="['/']"
                *ngIf="config?.layout.menuPosition === 'Top'"
              >
                <ng-container
                  *ngIf="initializerService.appData$ | async as logoImg"
                >
                  <div class="logo-img">
                    <img class="logo-img" alt="" [src]="logoImg?.logoLink" />
                  </div>
                  <span class="text">{{ logoImg.entityName }}</span>
                </ng-container>
              </a>
            </div>
          </li>
        </ul>
      </div> -->
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav d-flex align-items-center flex-row">
          <!-- <li class="nav-item d-lg-block mr-2 mt-1">
            <div class="search-bar">
              <div class="group">
                <svg class="search-icon" aria-hidden="true" viewBox="0 0 24 24">
                  <g>
                    <path
                      d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                    ></path>
                  </g>
                </svg>
                <input placeholder="Search" type="search" class="input" />
              </div>
            </div>
          </li> -->
          <li class="nav-item d-lg-block mr-2">
            <!-- <div direction="row" class="sc-hjWSAi garigO sc-dYdBQb hHaquw">
              <div
                size="16"
                class="sc-gVgnHT jZxEXX sc-zzdIa iMNvuR"
                (click)="switchLayout()"
              >
                <svg
                  *ngIf="config.layout.variant === 'Light'"
                  width="20px"
                  height="20px"
                  viewBox="0 2 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="🔍-Product-Icons"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="ic_fluent_dark_theme_24_regular"
                      fill="#212121"
                      fill-rule="nonzero"
                    >
                      <path
                        d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,20.5 L12,3.5 C16.6944204,3.5 20.5,7.30557963 20.5,12 C20.5,16.6944204 16.6944204,20.5 12,20.5 Z"
                        id="🎨-Color"
                      ></path>
                    </g>
                  </g>
                </svg>

                <svg
                  *ngIf="config.layout.variant === 'Dark'"
                  width="20px"
                  height="20px"
                  viewBox="0 2 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="🔍-Product-Icons"
                    stroke="#fff"
                    stroke-width="1"
                    fill="#fff"
                    fill-rule="evenodd"
                  >
                    <g
                      id="ic_fluent_dark_theme_24_regular"
                      fill="#fff"
                      fill-rule="nonzero"
                    >
                      <path
                        d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,20.5 L12,3.5 C16.6944204,3.5 20.5,7.30557963 20.5,12 C20.5,16.6944204 16.6944204,20.5 12,20.5 Z"
                        id="🎨-Color"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
            </div> -->
            <a
              (click)="switchLayout()"
              class="nav-link d-flex align-items-center"
              href="javascript:;"
            >
              <svg
                *ngIf="config.layout.variant === 'Light'"
                width="20px"
                height="20px"
                viewBox="0 2 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="🔍-Product-Icons"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="ic_fluent_dark_theme_24_regular"
                    fill="#212121"
                    fill-rule="nonzero"
                  >
                    <path
                      d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,20.5 L12,3.5 C16.6944204,3.5 20.5,7.30557963 20.5,12 C20.5,16.6944204 16.6944204,20.5 12,20.5 Z"
                      id="🎨-Color"
                    ></path>
                  </g>
                </g>
              </svg>

              <svg
                *ngIf="config.layout.variant === 'Dark'"
                width="20px"
                height="20px"
                viewBox="0 2 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="🔍-Product-Icons"
                  stroke="#fff"
                  stroke-width="1"
                  fill="#fff"
                  fill-rule="evenodd"
                >
                  <g
                    id="ic_fluent_dark_theme_24_regular"
                    fill="#fff"
                    fill-rule="nonzero"
                  >
                    <path
                      d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,20.5 L12,3.5 C16.6944204,3.5 20.5,7.30557963 20.5,12 C20.5,16.6944204 16.6944204,20.5 12,20.5 Z"
                      id="🎨-Color"
                    ></path>
                  </g>
                </g>
              </svg>
            </a>
          </li>
          <li
            class="i18n-dropdown dropdown nav-item mr-2"
            display="dynamic"
            ngbDropdown
          >
            <a
              class="nav-link d-flex align-items-center dropdown-toggle"
              id="dropdown-flag"
              href="javascript:;"
              data-toggle="dropdown"
              ngbDropdownToggle
              ><img
                class="langimg selected-flag"
                [src]="selectedLanguageFlag"
                alt="flag"
              /><span class="selected-language d-md-flex d-none">{{
                selectedLanguageText
              }}</span></a
            >
            <div
              ngbDropdownMenu
              class="dropdown-menu dropdown-menu-right text-left"
              aria-labelledby="dropdown-flag"
            >
              <a
                class="dropdown-item"
                href="javascript:;"
                data-language="en"
                (click)="ChangeLanguage('en')"
                ><img
                  class="langimg mr-2"
                  src="./assets/img/flags/us.png"
                  alt="flag"
                /><span class="font-small-3">English</span></a
              ><a
                class="dropdown-item"
                href="javascript:;"
                data-language="es"
                (click)="ChangeLanguage('es')"
                ><img
                  class="langimg mr-2"
                  src="./assets/img/flags/es.png"
                  alt="flag"
                /><span class="font-small-3">Spanish</span></a
              ><a
                class="dropdown-item"
                href="javascript:;"
                data-language="pt"
                (click)="ChangeLanguage('pt')"
                ><img
                  class="langimg mr-2"
                  src="./assets/img/flags/pt.png"
                  alt="flag"
                /><span class="font-small-3">Portuguese</span></a
              >
              <a
                class="dropdown-item"
                href="javascript:;"
                data-language="de"
                (click)="ChangeLanguage('de')"
                ><img
                  class="langimg mr-2"
                  src="./assets/img/flags/de.png"
                  alt="flag"
                /><span class="font-small-3">German</span></a
              >
            </div>
          </li>
          <!-- <li
            class="dropdown nav-item"
            placement="bottom-left"
            display="static"
            ngbDropdown
          >
            <a
              class="nav-link dropdown-toggle dropdown-notification p-0 mt-2"
              id="drp-notification"
              href="javascript:;"
              data-toggle="dropdown"
              ngbDropdownToggle
              ><i class="ft-bell font-medium-3"></i
              ><span class="notification badge badge-pill badge-danger"
                >4</span
              ></a
            >
            <ul
              ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden"
            >
              <li class="dropdown-menu-header">
                <div
                  class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary"
                >
                  <div class="d-flex">
                    <i
                      class="ft-bell font-medium-3 d-flex align-items-center mr-2"
                    ></i
                    ><span class="noti-title">7 New Notification</span>
                  </div>
                  <span class="text-bold-400 cursor-pointer"
                    >Mark all as read</span
                  >
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <a
                  class="d-flex justify-content-between"
                  href="javascript:void(0)"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img
                          class="avatar"
                          src="assets/img/portrait/small/avatar-s-20.png"
                          alt="avatar"
                          height="45"
                          width="45"
                        />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Kate Young</span
                        ><small class="grey lighten-1 font-italic float-right"
                          >5 mins ago</small
                        >
                      </h6>
                      <small class="noti-text">Commented on your photo</small>
                      <h6 class="noti-text font-small-3 m-0">
                        Great Shot John! Really enjoying the composition on this
                        piece.
                      </h6>
                    </div>
                  </div>
                </a>
                <a
                  class="d-flex justify-content-between"
                  href="javascript:void(0)"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img
                          class="avatar"
                          src="assets/img/portrait/small/avatar-s-11.png"
                          alt="avatar"
                          height="45"
                          width="45"
                        />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Andrew Watts</span
                        ><small class="grey lighten-1 font-italic float-right"
                          >49 mins ago</small
                        >
                      </h6>
                      <small class="noti-text"
                        >Liked your album: UI/UX Inspo</small
                      >
                    </div>
                  </div>
                </a>
                <a
                  class="d-flex justify-content-between read-notification"
                  href="javascript:void(0)"
                >
                  <div class="media d-flex align-items-center py-0 pr-0">
                    <div class="media-left">
                      <div class="mr-3">
                        <img
                          src="assets/img/icons/sketch-mac-icon.png"
                          alt="avatar"
                          height="45"
                          width="45"
                        />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">Update</h6>
                      <small class="noti-text">MyBook v2.0.7</small>
                    </div>
                    <div class="media-right">
                      <div class="border-left">
                        <div class="px-4 py-2 border-bottom">
                          <h6 class="m-0 text-bold-600">Update</h6>
                        </div>
                        <div class="px-4 py-2 text-center">
                          <h6 class="m-0">Close</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  class="d-flex justify-content-between read-notification"
                  href="javascript:void(0)"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-primary bg-lighten-3 mr-3 p-1">
                        <span class="avatar-content font-medium-2">LD</span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Registration done</span
                        ><small class="grey lighten-1 font-italic float-right"
                          >6 hrs ago</small
                        >
                      </h6>
                    </div>
                  </div>
                </a>
                <div class="cursor-pointer">
                  <div
                    class="media d-flex align-items-center justify-content-between"
                  >
                    <div class="media-left">
                      <div class="media-body">
                        <h6 class="m-0">New Offers</h6>
                      </div>
                    </div>
                    <div class="media-right">
                      <div class="custom-switch custom-control">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="custom-switch-1111"
                          checked
                        />
                        <label
                          class="custom-control-label mr-1"
                          for="custom-switch-1111"
                        >
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between cursor-pointer read-notification"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-danger bg-lighten-4 mr-3 p-1">
                        <span class="avatar-content font-medium-2"
                          ><i class="ft-heart text-danger"></i
                        ></span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Application approved</span
                        ><small class="grey lighten-1 font-italic float-right"
                          >18 hrs ago</small
                        >
                      </h6>
                    </div>
                  </div>
                </div>
                <a
                  class="d-flex justify-content-between read-notification"
                  href="javascript:void(0)"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img
                          class="avatar"
                          src="assets/img/portrait/small/avatar-s-6.png"
                          alt="avatar"
                          height="45"
                          width="45"
                        />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Anna Lee</span
                        ><small class="grey lighten-1 font-italic float-right"
                          >27 hrs ago</small
                        >
                      </h6>
                      <small class="noti-text">Commented on your photo</small>
                      <h6 class="noti-text font-small-3 text-bold-500 m-0">
                        Woah!Loving these colors! Keep it up
                      </h6>
                    </div>
                  </div>
                </a>
                <div
                  class="d-flex justify-content-between cursor-pointer read-notification"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-info bg-lighten-4 mr-3 p-1">
                        <div class="avatar-content font-medium-2">
                          <i class="ft-align-left text-info"></i>
                        </div>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Report generated</span
                        ><small class="grey lighten-1 font-italic float-right"
                          >35 hrs ago</small
                        >
                      </h6>
                    </div>
                  </div>
                </div>
                <a
                  class="d-flex justify-content-between read-notification"
                  href="javascript:void(0)"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img
                          class="avatar"
                          src="assets/img/portrait/small/avatar-s-7.png"
                          alt="avatar"
                          height="45"
                          width="45"
                        />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Oliver Wright</span
                        ><small class="grey lighten-1 font-italic float-right"
                          >2 days ago</small
                        >
                      </h6>
                      <small class="noti-text"
                        >Liked your album: UI/UX Inspo</small
                      >
                    </div>
                  </div>
                </a>
              </li>
              <li class="dropdown-menu-footer">
                <div
                  class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1"
                >
                  Read All Notifications
                </div>
              </li>
            </ul>
          </li> -->
          <li
            class="dropdown nav-item mr-1"
            placement="bottom-left"
            display="static"
            ngbDropdown
          >
            <a
              class="nav-link dropdown-toggle user-dropdown d-flex align-items-end"
              id="dropdownBasic2"
              href="javascript:;"
              data-toggle="dropdown"
              ngbDropdownToggle
            >
              <!-- <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{ this.loginService.username }}</span>
                <span
                  class="text-right text-muted font-small-3">Available</span>
              </div> -->
              <!-- <img
                class="avatar"
                src="assets/img/portrait/small/avatar-s-1.png"
                alt="avatar"
                height="35"
                width="35"
              /> -->
              <img
                class="avatar"
                src="assets/img/user2.png"
                alt="avatar"
                height="35"
                width="35"
              />
            </a>
            <div
              class="dropdown-menu text-left dropdown-menu-right m-0 pb-0"
              aria-labelledby="dropdownBasic2"
              ngbDropdownMenu
            >
              <!-- <a class="dropdown-item" routerLink="/chat">
                <div class="d-flex align-items-center">
                  <i class="ft-message-square mr-2"></i><span>Chat</span>
                </div>
              </a>
              <a class="dropdown-item" routerLink="/pages/profile">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Edit Profile</span>
                </div>
              </a>
              <a class="dropdown-item" routerLink="/inbox">
                <div class="d-flex align-items-center">
                  <i class="ft-mail mr-2"></i><span>My Inbox</span>
                </div>
              </a> -->
              <a class="dropdown-item">
                <div class="d-flex align-items-center">
                  <i class="ft-user mr-2"></i
                  ><span>{{ this.loginService.username }}</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="settings">
                <div class="d-flex align-items-center">
                  <i class="ft-settings mr-2"></i><span>Settings</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="change-password">
                <div class="d-flex align-items-center">
                  <i class="ft-lock mr-2"></i><span>Change password</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>

          <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a
              class="nav-link notification-sidebar-toggle"
              (click)="toggleNotificationSidebar()"
              ><i class="ft-align-right font-medium-3"></i
            ></a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</nav>
