import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from '../login.service';
import { AppService } from 'app/app.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  profilePort() {
    return this.appService.mffURL + ":" + this.loginService.portnumber() + "/WorkflowService";
  }

  constructor(private httpClient:HttpClient,private loginService:LoginService,private appService:AppService) { }

  getWorkflows() {
    return this.httpClient.get(this.profilePort() + '/workflows').
      map((response) => response).catch(this.errorHandler);
  }

  errorHandler(error) {
    let errorMessage = JSON.parse(error._body);
      Swal.fire({
        title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
        html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        focusConfirm: true,
        icon: "error",
      });
    return Observable.throw(error || " server error ");
  }
}
