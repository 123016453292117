import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ConfigService } from './config.service';

interface EntityData {
  logoLink: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

 // Default font
 private _selectedFont: string = 'Times New Roman , serif'; 

 private renderer: Renderer2;  // Renderer2 for DOM manipulation

 // Default Dark theme color
 private _defaultDarkThemeColor: string = '#cdcddd'


 // BehaviorSubject to hold the logo URL
 private _logo: BehaviorSubject<string> = new BehaviorSubject<string>('assets/img/smalllogo.png');

 // BehaviorSubject to hold the entity name
 private _entity: BehaviorSubject<string> = new BehaviorSubject<string>("");

 color:string;

  constructor(private rendererFactory: RendererFactory2,private httpClient:HttpClient,private config:ConfigService) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Get color for dark theme
   *
   * @return {string} color
   */
  public get defaultDarkThemeColor(): string {
    return this._defaultDarkThemeColor;
  }


  /**
   * Set the theme color.
   *
   * @param {string} color - The new theme color.
   */
  set themeColor(color: string) {
    this.color = color;
  }

  /**
   * Set the selected font and apply it to the document body.
   *
   * @param {string} font - The font to be set.
   * @return {void}
   */
  setFont(font: string): void {
    this._selectedFont = font;
    this.renderer.setStyle(document.body, 'font-family', font);
  }

  /**
   * Get the selected font.
   *
   * @return {string} The selected font.
   */
  get font(): string {
    return this._selectedFont;
  }

  /**
   * Set the logo URL.
   *
   * @param {string} url - The new logo URL.
   */
  set logo(url: string) {
    this._logo.next(url);
  }

  /**
   * Get the logo URL as an Observable.
   *
   * @return {Observable<string>}
   */
  get logo$(): Observable<string> {
    return this._logo.asObservable();
  }

  /**
   * Set the entity name.
   *
   * @param {string} name - The new entity name.
   */
  set entity(name: string) {
    this._entity.next(name);
  }

  /**
   * Get the entity name as an Observable.
   *
   * @return {Observable<string>}
   */
  get entity$(): Observable<string> {
    return this._entity.asObservable();
  }

  /**
   * Get a logo from a URL using an HTTP GET request.
   *
   * @param {string} url - The URL of the logo.
   * @return {Observable<any>} The HTTP response.
   */
  getLogo(url: string): Observable<any> {
    return this.httpClient.get(url,{responseType:'blob'});
  }

  /**
   * Apply the theme color to the text color of the document body.
   * @param {string} color - The theme color to be applied.
   * @return {void}
   */
  applyThemeColor(color: string): void {
    const root = document.documentElement;
    root.style.setProperty('--theme-color', color);
    this.renderer.setStyle(document.body, 'color', color);
  }


   /**
   * Change the theme color to the text color of the document body.
   * @param {string} color - The theme color to be applied.
   * @return {void}
   */
   changeThemeColor(color: string): void {
    // this.color = color; // Set color for light theme 
    // console.log(color)
    const root = document.documentElement;
    root.style.setProperty('--theme-color', color);
   //this.renderer.setStyle(document.body, 'color', color);
  }

  get themeColor():string{
    return this.color
  }

  /**
   * Add hover effects to buttons with class .btn-primary.
   *
   * @return {void}
   */
  addHoverEffectsToButtons(): void {
    const buttons = document.querySelectorAll('.btn-primary');
    buttons.forEach((button) => {
      this.renderer.setStyle(button, 'background-color', this.themeColor);
    });
  }

  changeNg2SmartTableHeaderColor(){
    const title = document.querySelectorAll('ng2-st-column-title');
    if(Array.from(title).length > 0){
      Array.from(title).forEach((element)=>{
        element.getElementsByTagName("a")[0].style.color = this.themeColor;
      })
    }
  }
}
