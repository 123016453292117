export const commonEnvironment = {
  protocol: "https://",

  // MFF variables
  port: window?.["env"]?.baseUrlPort,

  serverUrl: window?.["env"]?.serverUrl || "webapp.mobilefirstfinance.com",

  // baseApiUrl: window?.["env"]?.baseApiUrl || "uat.mobilefirstfinance.com",

  baseApiUrl: window?.["env"]?.baseApiUrl || "sandbox.mobilefirstfinance.com",

  rootUser: window?.["env"]?.rootUser || "TEST",

  redirectLoginUri: "", // To be set later
  redirectUri: "", // To be set later
  mffURL: "", // To be set later

  // Keycloak variables
  clientId: window?.["env"]?.keycloakClientId || "mff",

  clientSecret:
    window?.["env"]?.keycloakClientSecret || "eKmGhZhSQuiolxfvTHyw7J2SsKSbUgoN",

  realm: window?.["env"]?.keycloakRealm || "Sandbox",

  keycloakBaseApiUrl:
    window?.["env"]?.keycloakBaseApiUrl || "keycloak.mobilefirstfinance.com",

  keycloakPort: window?.["env"]?.keycloakPort || "8443",

  keycloakUrl: "", // To be set later
};

// Setting dynamic URLs using template strings
commonEnvironment[
  "redirectUri"
] = `${commonEnvironment.protocol}${commonEnvironment.serverUrl}/pages/register-successfull-page`;

commonEnvironment[
  "redirectLoginUri"
] = `${commonEnvironment.protocol}${commonEnvironment.serverUrl}`;

commonEnvironment[
  "keycloakUrl"
] = `${commonEnvironment.protocol}${commonEnvironment.keycloakBaseApiUrl}:${commonEnvironment.keycloakPort}`;
commonEnvironment[
  "mffURL"
] = `${commonEnvironment.protocol}${commonEnvironment.baseApiUrl}`;
