import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FontService {
  private _selectedFont: string = 'serif'; // default font
  
  private renderer2:Renderer2;

  constructor(private rendererFactory:RendererFactory2){
    this.renderer2 = rendererFactory.createRenderer(null,null)
  }

  set font(font: string) {
    this._selectedFont = font;
    this.renderer2.setStyle(document.body,'font-family',font)
  }
  
  get font() {
    return this._selectedFont;
  }

  updateFont(font:string){
    // API need to integrate
  }
  
}
