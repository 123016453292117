import { HttpService } from "app/core/services/http.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "app/core/auth/auth.service";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

export enum REQUIRED_ACTIONS {
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  VERIFY_EMAIL = "VERIFY_EMAIL",
}

@Injectable({
  providedIn: "root",
})
export class KeycloakService {
  private keycloakBaseUrl = environment.keycloakUrl;
  private realm = environment.realm;
  private clientId = environment.clientId;
  private clientSecret = environment.clientSecret;

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private httpClient: HttpClient
  ) {}

  private async sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
  }

  private async generateCodeChallenge(codeVerifier: string): Promise<string> {
    const hash = await this.sha256(codeVerifier);
    return btoa(String.fromCharCode(...new Uint8Array(hash)))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }

  async socialLogin(provider: string) {
    const codeVerifier = this.getRandomString(128);
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);
    const codeChallengeMethod = "S256";

    localStorage.setItem("pkce_code_verifier", codeVerifier);

    const authUrl = `${this.keycloakBaseUrl}/realms/${
      this.realm
    }/protocol/openid-connect/auth?client_id=${
      this.clientId
    }&redirect_uri=${encodeURIComponent(
      environment.redirectLoginUri
    )}&response_type=code&scope=openid profile email&response_mode=query&kc_idp_hint=${provider}&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}&userExist=''`;
    window.location.href = authUrl;
  }

  getRandomString(length: number): string {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }
    return result;
  }

  async exchangeCodeForToken(code: string, redirectUri?: string): Promise<any> {
    const codeVerifier = localStorage.getItem("pkce_code_verifier");
    if (!codeVerifier) {
      throw new Error("Code verifier not found in localStorage");
    }

    const tokenUrl = `${this.keycloakBaseUrl}/realms/${this.realm}/protocol/openid-connect/token`;

    const params = new HttpParams()
      .set("client_id", this.clientId)
      .set("grant_type", "authorization_code")
      .set("redirect_uri", environment.redirectLoginUri)
      .set("code", code)
      .set("code_verifier", codeVerifier)
      .set("client_secret", this.clientSecret);

    const response = await this.httpService.httpWithoutInterceptor
      .post(tokenUrl, params.toString(), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .toPromise();

    // Check if response contains expected properties
    if (!response || !response["access_token"]) {
      throw new Error("Failed to obtain access token");
    }

    return response;
  }

  async loadUserProfileFromKeycloakUsingExchangeCode(): Promise<any> {
    const userInfoUrl = `/realms/${this.realm}/protocol/openid-connect/userinfo`;
    const response = await this.httpClient
      .get(userInfoUrl, {
        // headers: {
        //   Authorization: `Bearer ${this.authService.getToken()}`,
        // },
      })
      .toPromise();
    // Check if response contains expected properties
    if (!response) {
      throw new Error("Failed to obtain access token");
    }

    return response;
  }

  async getUserCredentialsByUserId(userId) {
    const credentialUrl = `/admin/realms/${this.realm}/users/${userId}/credentials`;
    const response = await this.httpClient
      .get<any[]>(credentialUrl, {
        // headers: {
        //   Authorization: `Bearer ${this.authService.getClientAuthToken()}`,
        // },
        observe: "response",
      })
      .map((res) => res.body || [])
      .toPromise();

    // Check if response contains expected properties
    if (!response) {
      throw new Error("Failed to obtain access token");
    }

    return response;
  }

  updatePasswordByUserId(payload, userId) {
    return this.httpClient
      .put<any>(
        `/admin/realms/${environment.realm}/users/${userId}/reset-password`,

        payload,
        {
          // headers: {
          //   Authorization: `Bearer ${this.authService.getClientAuthToken()}`,
          // },
          observe: "response",
        }
      )
      .pipe(
        map((res) => {
          const statusCode = res.status;
          const responseBody = res.body;

          return {
            status: statusCode,
            message: "Password updated successfully",
            data: responseBody, // You can include the original response if needed
          };
        }),
        catchError(this.errorHandler)
      );
  }

  async loadUserProfile(): Promise<any> {
    const userInfoUrl = `/realms/${this.realm}/protocol/openid-connect/userinfo`;
    const response = await this.httpClient
      .get(userInfoUrl, {
        // headers: {
        //   Authorization: `Bearer ${this.authService.getToken()}`,
        // },
      })
      .toPromise();
    // Check if response contains expected properties
    if (!response) {
      throw new Error("Failed to obtain access token");
    }

    return response;
  }

  // Resend verification email

  sendVerifyEmail(userId) {
    const token = localStorage.getItem("client_access_token");

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    // return this.httpService.httpWithoutInterceptor
    //   .put<any>(
    //     `${environment.keycloakUrl}/admin/realms/${environment.realm}/users/${userId}/send-verify-email`,
    //     { redirect_uri: environment.redirectUri },
    //     {
    //       headers,
    //     }
    //   )
    //   .map((res) => {
    //     return res;
    //   })
    //   .catch(this.errorHandler);

    return this.httpClient
      .put<any>(
        `/admin/realms/${environment.realm}/users/${userId}/send-verify-email`,
        { redirect_uri: environment.redirectUri }
      )
      .map((res) => {
        return res;
      })
      .catch(this.errorHandler);
  }

  // Find the user by email

  getUserByEmail(email) {
    const token = localStorage.getItem("client_access_token");

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    });

    // return this.httpService.httpWithoutInterceptor
    //   .get<any>(
    //     `${environment.keycloakUrl}/admin/realms/${environment.realm}/users?email=${email}`,
    //     {
    //       headers,
    //     }
    //   )
    //   .map((res) => {
    //     return res;
    //   })
    //   .catch(this.errorHandler);

    return this.httpClient
      .get<any>(
        `/admin/realms/${environment.realm}/users?email=${email}`
        // {
        //   headers,
        // }
      )
      .map((res) => {
        return res;
      })
      .catch(this.errorHandler);
  }

  logoutKeycloakSession(userId) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    });

    return this.httpClient
      .post<any>(
        `/admin/realms/${environment.realm}/users/${userId}/logout`,
        null,
        {
          observe: "response",
          headers,
        }
      )
      .map((res) => res)
      .catch(this.errorHandler);
  }

  // registered account using keycloak
  registerAccountUsingKeycloak(payload) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("client_access_token"),
    });

    const body = {
      attributes: {
        userType: "External",
      },
      email: payload.get("email").value,
      username: payload.get("email").value,
      enabled: true,
      requiredActions: ["VERIFY_EMAIL"],
      credentials: [
        {
          type: "password",
          value: payload.get("password").value,
          temporary: false,
        },
      ],
    };

    // return this.httpService.httpWithoutInterceptor
    //   .post<any>(
    //     `${environment.keycloakUrl}/admin/realms/${environment.realm}/users`,
    //     body,
    //     {
    //       headers,
    //       observe: "response",
    //     }
    //   )
    //   .map((res) => {
    //     return res;
    //   })
    //   .catch(this.errorHandler);

    return this.httpClient
      .post<any>(`/admin/realms/${environment.realm}/users`, body, {
        // headers,
        observe: "response",
      })
      .map((res) => {
        return res;
      })
      .catch(this.errorHandler);
  }

  // Resend verification email

  verifyEmailUsingKeycloak(userId) {
    const token = localStorage.getItem("client_access_token");

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    console.log(headers);

    return this.httpClient
      .put<any>(
        `/admin/realms/${environment.realm}/users/${userId}/send-verify-email`,
        { redirect_uri: environment.redirectUri }
        // {
        //   headers,
        // }
      )
      .map((res) => {
        return res;
      })
      .catch(this.errorHandler);
  }

  // send reset password link
  sendResetPasswordLinkUsingKeycloak(userId: string): Observable<any> {
    const params = new HttpParams()
      .set("client_id", environment.clientId)
      .set("lifespan", 300)
      .set("redirect_uri", environment.redirectLoginUri);

    const payload = ["UPDATE_PASSWORD"];

    return this.httpClient
      .put(
        `/admin/realms/${environment.realm}/users/${userId}/execute-actions-email`,
        payload,
        {
          params,
        }
      )
      .map((res) => {
        return res;
      })
      .catch(this.errorHandler);
  }

  // reset password
  resetPasswordUsingKeycloak(
    userId: string,
    password: string
  ): Observable<any> {
    const payload = {
      type: "password",
      value: password,
      temporary: false,
    };
    return this.httpClient
      .put(
        `/admin/realms/${environment.realm}/users/${userId}/reset-password`,
        payload
      )
      .map((res) => {
        return res;
      })
      .catch(this.errorHandler);
  }

  errorHandler(error: Response) {
    if (error.status == 500) {
      alert("Invalid RootUser/UserName/Password");
    }
    return throwError(error || " server error ");
  }
}
