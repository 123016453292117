import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  constructor() {}

  private urlsToAddClientToken: string[] = [
    "/credentials",
    "/users/",
    "/users?email=",
    "/send-verify-email",
    "/users",
    "/logout",
  ];

  private urlsToSkipAuthorizationToken = [
    "/LoginService/login",
    "/userValidation",
    "/file",
    "/upload",
    "/root/entity",
    "assets/i18n/",
  ];

  getUrlsToAddClientToken(): string[] {
    return this.urlsToAddClientToken;
  }

  getUrlsToSkipAuthorizationToken(): string[] {
    return this.urlsToSkipAuthorizationToken;
  }
}
