import { DOCUMENT } from "@angular/common";
import {  Inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { AppInitializerService } from "app/core/initializer/services/app-initializer.service";
import { FontService } from "./font.service";
import { ThemeService } from "./theme.service";

@Injectable({
  providedIn: "root",
})
export class CustomTitleStrategyService extends TitleStrategy {
  private entityName: string;
  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title) {
      this.title.setTitle(`${this.entityName} | ${title}`);
    }
  }

  constructor(
    private title: Title,
    private appInitializer: AppInitializerService,
    private fontService:FontService,
    private themeService:ThemeService,
    @Inject(DOCUMENT) private _document:Document
  ) {
    super();
    this.appInitializer.appData$.subscribe({
      next: (res) => {
        this.entityName = res.entityName;
        this._document.getElementById('appFavicon').setAttribute('href', res?.faviconIcon);
        this.fontService.font = res?.font || 'feather , serif'
      },
      error: (err) => err,
    });
  }

  set appTitle(name:string){
    this.entityName = name;
  }
}
