import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { LoginService } from "app/shared/services/login.service";
import { PortFolioService } from "app/shared/services/portFolio.service";
import { LoaderService } from "app/shared/services/loader.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  public profileId: any;
  public profileFormId: any;
  public branches: any;
  public profileFormData: any;
  public access_token: string;
  public baseUrl: string;
  public treeLevel;
  public baseLink: string;

  public profileBaseUrl: string;

  private responseFormat = {
    observe: "body",
    responseType: "json",
  };

  constructor(
    private httpClient: HttpClient,
    private portfolioService: PortFolioService,
    private loginService: LoginService, // private branchService: BranchesService,
    private loaderService: LoaderService
  ) {}

  provideProfilePagination(pgNum, records) {
    let params = new HttpParams()
      .set("pageNumber", pgNum)
      .set("numberOfRecords", records);
    return this.httpClient
      .get(`/ProfileService/profiles`, {
        observe: "body",
        responseType: "json",
        params,
      })
      .map((response) => response)
      .catch(this.errorHandler);
  }

  provideProfileFormByProfileId(profileID) {
    let params = new HttpParams().set("formId", profileID);
    return this.httpClient
      .get(`/ProfileService/profiles/forms`, {
        observe: "body",
        responseType: "json",
        params,
      })
      .map((res) => {
        return res;
      });
  }

  provideProfileForms() {
    return this.httpClient
      .get(`/ProfileService/profiles/forms`, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => {
        return res;
      });
  }

  provideProfileById(ProfileID) {
    return this.httpClient
      .get(`/ProfileService/profiles/${ProfileID}`, {
        observe: "body",
        responseType: "json",
      })
      .map((response) => response)
      .catch(this.errorHandler);
  }

  filterProfilesWithPagination(filterData, pgNo, noOfRecords) {
    let params = new HttpParams();

    if (filterData.Identifier) {
      params = params.set("identifier", filterData.Identifier);
    }

    if (filterData.Hierarchy) {
      params = params.set("Hierarchy", filterData.Hierarchy);
    }

    if (filterData.Name) {
      params = params.set("name", filterData.Name);
    }

    if (filterData.NationalID) {
      params = params.set("nationalId", filterData.NationalID);
    }

    if (filterData.mobileNumber) {
      params = params.set("mobileNumber", filterData.mobileNumber);
    }

    params = params.set("pageNumber", 0);
    params = params.set("numberOfRecords", noOfRecords ?? 10);

    return this.httpClient
      .get(`/ProfileService/searchProfiles`, { params })
      .map((response) => response)
      .catch(this.errorHandler);
  }

  updateProfile(selectProfile, ProfileID) {
    return this.httpClient
      .put(`/ProfileService/profiles/${ProfileID}`, selectProfile, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => res)
      .catch(this.errorHandler);
  }

  provideProfileFormById(profileFormId) {
    return this.httpClient
      .get(`/ProfileService/profiles/forms/${profileFormId}`, {
        observe: "body",
        responseType: "json",
      })
      .map((response) => {
        return response;
      })
      .catch(this.errorHandler);
  }

  getFileList(type, profID = "cc12ef43-ed54-46df-8c03-75ee57b3af16") {
    return this.httpClient
      .get(`/ProfileService/files/${profID}/${type}`, {
        observe: "body",
        responseType: "json",
      })
      .map((response: HttpResponse<any>) => response);
  }

  updateProfileAsw(profileDetails, profID) {
    return this.httpClient
      .put(`/SolitxService/ProfilesService/profiles/${profID}`, profileDetails)
      .map((res) => res)
      .catch(this.errorHandler);
  }

  createProfile(selectProfile) {
    return this.httpClient
      .post(`/ProfileService/profiles`, selectProfile, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => res)
      .catch(this.errorHandler);
  }

  searchBranchName(branchName = "") {
    let params = new HttpParams().set("branchName", branchName);
    return this.httpClient
      .get(`/PortfolioService/hierarchySearch`, {
        observe: "body",
        responseType: "json",
        params,
      })
      .map((response) => response)
      .catch(this.errorHandler);
  }

  provideLinkMenuToRole(roleId) {
    const params = new HttpParams().set("roleId", roleId ?? "");
    return this.httpClient
      .get(`/UserService/provideLinkMenuToRole`, { params })
      .map((res) => res)
      .catch(this.errorHandler);
  }

  getLogo() {
    return this.httpClient
      .get(`/ProfileService/logos/${this.loginService.rootUserID}/personal`, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => {
        return { document: res["data"]["documents"] };
      });
  }

  addProductTemplateFile(productId, frmData) {
    const params = new HttpParams().set("productId", productId);
    const headers = new HttpHeaders();
    headers.append("Accept", "multipart/form-data");
    return this.httpClient
      .post(`/ProfileService/upload/productTemplate`, frmData, {
        headers,
        params,
      })
      .map((res) => {
        return res;
      });
  }

  getProductTemplateFile(productId) {
    return this.httpClient
      .get(`/ProfileService/product/${productId}`, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => {
        return res;
      });
  }

  getAllBranchNames() {
    return this.httpClient
      .get(`${this.portfolioService.portfolioport()}/hierarchySearch`, {
        observe: "body",
        responseType: "json",
      })
      .map((response) => response)
      .catch(this.errorHandler);
  }

  getAllBranches(branchid) {
    const params = new HttpParams().set("branchid", branchid);
    return this.httpClient
      .get(`/ProfileService +"/branches/hierarchy`, {
        observe: "body",
        responseType: "json",
        params,
      })
      .map((response) => response)
      .catch(this.errorHandler);
  }

  getProducts() {
    const params = new HttpParams().set("productID", "");

    return this.httpClient
      .get(`/SolitxService/ProductService/products`, {
        observe: "body",
        responseType: "json",
        params,
      })
      .map((res) => res);
  }

  uploadFilesToProfile(pID, type, value) {
    const headers = new HttpHeaders();
    headers.append("Accept", "multipart/form-data");

    return this.httpClient
      .post(`/ProfileService/upload/files/${pID}/${type}`, value, { headers })
      .map((res) => res)
      .catch(this.errorHandler);
  }

  errorHandler(error) {
    this.loaderService?.hideLoader();
    let errorMessage = JSON.parse(error._body);
    Swal.fire({
      title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
      html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
      showCloseButton: true,
      showConfirmButton: false,
      showCancelButton: false,
      focusConfirm: true,
      icon: "error",
    });
    return Observable.throw(error || " server error ");
  }
}
