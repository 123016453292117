import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { HROUTES } from "./HROUTES";
import { LayoutService } from "../services/layout.service";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { MenuService } from "../services/menu-service/menu.service";
import { LoginService } from "../services/login.service";
import { WorkflowService } from "../services/workflow-service/workflow.service";

@Component({
  selector: "app-horizontal-menu",
  templateUrl: "./horizontal-menu.component.html",
  styleUrls: ["./horizontal-menu.component.scss"],
})
export class HorizontalMenuComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public menuItems: any[];
  public config: any = {};
  level: number = 0;
  transparentBGClass = "";
  menuPosition = "Side";

  layoutSub: Subscription;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private menuService: MenuService,
    private loginService: LoginService,
    private workflowService: WorkflowService
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    this.menuService.sharedMenuData$.subscribe((menus) => {
      if (menus.length > 0) {
        const filterdMenus = HROUTES.filter((menu) =>
          menus
            .sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence))
            .some((m) => m.menuName == menu.title)
        );
        this.menuItems = filterdMenus;
      } else {
        this.menuItems = HROUTES;
      }

      this.cdr.detectChanges();
    });
  }

  async getFilterMenus(menus) {
    const filterdMenus = HROUTES.filter((menu) =>
      menus
        .sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence))
        .some((m) => m.menuName == menu.title)
    );
    return filterdMenus;
  }

  ngAfterViewInit() {
    this.layoutSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );
  }

  loadLayout() {
    if (
      this.config.layout.menuPosition &&
      this.config.layout.menuPosition.toString().trim() != ""
    ) {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = "";
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }
}
