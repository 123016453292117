import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
// import { Http, Response, RequestOptions, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { AppService } from "../../app.service";
import { Observable } from "rxjs/Observable";
import Swal from "sweetalert2";
import { LoginService } from "./login.service";
import { BehaviorSubject, throwError } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
// import { BranchesService } from "../../branches/branches.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public editUserDetails: any;
  public access_token: any;
  public editUserID: any;
  public userFormTamplateId: any;
  public userCreation: Object;
  public tabIndex: any;

  public userInfo = new BehaviorSubject<any | null>(null);
  readonly userInfo$ = this.userInfo.asObservable().pipe(
    filter((data) => !!data),
    map((data) => data)
  );

  public headers = new Headers({
    "Content-Type": "application/json",
    // 'Authorization':this.loginService.bearer_token
  });
  // public options = new RequestOptions({ headers: this.headers });

  constructor(
    // private http: Http,
    private loginService: LoginService,
    private commonservice: AppService,
    // private branchesService: BranchesService,
    private _httpClient: HttpClient
  ) {
    console.log("user service");
    this.access_token = loginService.access_tokenMethod();
  }

  getUserInfo() {
    return this._httpClient
      .get(`/UserService/users/root`, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => {
        return { users: res["data"]["users"] };
      });
  }

  updateRootUser(userId) {
    return this._httpClient
      .put(`/UserService/updateRootuser/${userId}`, {
        observe: "body",
        responseType: "json",
      })
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  checkUserValidation(payload) {
    return this._httpClient
      .post("/UserService" + "/userValidation", payload, {
        observe: "body",
        responseType: "json",
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // getUserFormByID(formID) {
  //   return this.http
  //     .get(
  //       '/UserService' +
  //         "/users/forms/" +
  //         formID +
  //         "?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }

  getUser() {
    return this._httpClient
      .get(`/UserService/users`)
      .map((res: HttpResponse<any>) => res)
      .catch(this.errorHandler);
  }

  // getUserHeirarchy() {
  //   return this.http
  //     .get(
  //       '/UserService' +
  //         "/users/data/all?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  getUserbyID(UserID) {
    return this._httpClient
      .get(`/UserService/users/${UserID}`, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => res)
      .catch(this.errorHandler);
  }
  // createUser(userDetails) {
  //   return this.http
  //     .post(
  //       '/UserService' + `/users?access_token=` + this.access_token,
  //       userDetails
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // editUserDetailsService(userDetails, userId) {
  //   //alert(JSON.stringify('/UserService'+'/users/'+userId+'?access_token='+this.access_token))
  //   return this.http
  //     .put(
  //       '/UserService' +
  //         "/users/" +
  //         userId +
  //         "?access_token=" +
  //         this.access_token,
  //       userDetails
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // deleteUser(deleteUserID) {
  //   return this.http
  //     .delete(
  //       '/UserService' +
  //         `/users/` +
  //         deleteUserID +
  //         "?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // attachDetachRoles(userId, roleDetails) {
  //   ////alert('/UserService'+'/users/'+userId+'/roles?access_token='+this.access_token);
  //   return this.http
  //     .put(
  //       '/UserService' +
  //         "/users/" +
  //         userId +
  //         "/roles?access_token=" +
  //         this.access_token,
  //       roleDetails
  //     )
  //     .map((res: Response) => {
  //       ////lert(res.status);

  //       return res.json();
  //     })
  //     .catch(this.errorHandler);
  // }
  // attachDetachGroups(userId, roleDetails) {
  //   //alert('/UserService'+'/users/'+userId+'/groups?access_token='+this.access_token);
  //   return this.http
  //     .put(
  //       '/UserService' +
  //         "/users/" +
  //         userId +
  //         "/groups?access_token=" +
  //         this.access_token,
  //       roleDetails
  //     )
  //     .map((res: Response) => {
  //       ////lert(res.status);

  //       return res.json();
  //     })
  //     .catch(this.errorHandler);
  // }
  // attachDetachRolesGet(userId) {
  //   return this.http
  //     .get(
  //       '/UserService' +
  //         "/users/" +
  //         userId +
  //         "/roles?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // attachDetachGroupsGet(userId) {
  //   return this.http
  //     .get(
  //       '/UserService' +
  //         "/users/" +
  //         userId +
  //         "/groups?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // getLinkedBranches(userID) {
  //   return this.http
  //     .get(
  //       this.branchesService.branchPort() +
  //         "/provideLinkedUserToBranch/" +
  //         userID +
  //         "?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }

  // updateLinkUserToBranch(userId, branchesLinked) {
  //   //alert(JSON.stringify('/UserService'+'/users/'+userId+'?access_token='+this.access_token))
  //   return this.http
  //     .put(
  //       this.branchesService.branchPort() +
  //         "/updateLinkUserToBranch/" +
  //         userId +
  //         "?access_token=" +
  //         this.access_token,
  //       branchesLinked
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // editUserForm(userDetails, userFormTemplateID) {
  //   return this.http
  //     .put(
  //       '/UserService' +
  //         "/users/forms/" +
  //         userFormTemplateID +
  //         "?access_token=" +
  //         this.access_token,
  //       userDetails
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // createUserForms(userForms) {
  //   //alert(userForms);
  //   return this.http
  //     .post(
  //       '/UserService' +
  //         `/users/forms?access_token=` +
  //         this.access_token,
  //       userForms
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // createUserHierarchy(userID, payload) {
  //   //alert(userForms);
  //   return this.http
  //     .post(
  //       '/UserService' +
  //         "/saveuserbyuserhirarchy/" +
  //         userID +
  //         "?access_token=" +
  //         this.access_token,
  //       payload
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // getRootUserByID(UserID) {
  //   return this.http
  //     .get(
  //       '/UserService' +
  //         "/users/root/" +
  //         UserID +
  //         "?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // getRootUser() {
  //   return this.http
  //     .get(
  //       '/UserService' +
  //         "/users/root?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }

  // OauthDetails() {
  //   return this.http
  //     .get(
  //       '/UserService' +
  //         `/oauthDetails/` +
  //         "?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }

  // provideRootUserVisibility(UserID) {
  //   return this.http
  //     .get(
  //       '/UserService' +
  //         "/users/root/" +
  //         UserID +
  //         "/visibility?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // editRootUserDetailsVisibility(userId, visibility) {
  //   return this.http
  //     .put(
  //       '/UserService' +
  //         "/users/root/" +
  //         userId +
  //         "/visibility/" +
  //         visibility +
  //         "?access_token=" +
  //         this.access_token,
  //       visibility
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }

  // editRootUserDetails(userId, data) {
  //   return this.http
  //     .put(
  //       '/UserService' +
  //         "/users/root/" +
  //         userId +
  //         "?access_token=" +
  //         this.access_token,
  //       data
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // getRootconfig(id) {
  //   return this.http
  //     .get(
  //       '/UserService' +
  //         "/users/root/" +
  //         id +
  //         "/config?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // getuserconfig(id) {
  //   return this.http
  //     .get(
  //       '/UserService' +
  //         "/users/" +
  //         id +
  //         "/config?access_token=" +
  //         this.access_token
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }

  // editRootUserConfig(id, data) {
  //   return this.http
  //     .put(
  //       '/UserService' +
  //         "/users/root/" +
  //         id +
  //         "/configs?access_token=" +
  //         this.access_token,
  //       data
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  // editUserConfig(id, data) {
  //   return this.http
  //     .put(
  //       '/UserService' +
  //         "/users/" +
  //         id +
  //         "/config?access_token=" +
  //         this.access_token,
  //       data
  //     )
  //     .map((res: Response) => res.json())
  //     .catch(this.errorHandler);
  // }
  errorHandler(error) {
    let errorMessage = JSON.parse(error._body);
    Swal.fire({
      title: "Sorry!",
      text: errorMessage.message,
      icon: "warning",
    });
    return Observable.throw(error || " server error ");
  }
}
