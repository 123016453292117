import { Injectable, inject } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams,
} from "@angular/common/http";
import { Observable, from, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { ConfigService } from "../services/config.service";
import { environment } from "environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  // Injecting AuthService to access tokens and refresh mechanism
  private authService = inject(AuthService);

  // Injecting ConfigService to get url's
  private configService = inject(ConfigService);

  /**
   * Intercepts HTTP requests and applies authorization logic if required.
   * Skips certain URLs from authorization, and handles token refresh logic.
   *
   * @param {HttpRequest<any>} request - The outgoing HTTP request.
   * @param {HttpHandler} next - The next interceptor in the chain.
   * @returns {Observable<HttpEvent<any>>} - The observable for the HTTP event.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const urlsToSkipAuth = this.configService.getUrlsToSkipAuthorizationToken();

    // Check if the request URL should bypass authorization
    const skipAuth = urlsToSkipAuth.some((url) => request.url.includes(url));

    if (skipAuth) {
      // Add authorization header only for file or upload requests
      if (request.url.includes("/file") || request.url.includes("/upload")) {
        return next.handle(
          this.cloneRequestWithToken(request, this.authService.getToken())
        );
      }
      return next.handle(request);
    }

    // For all other requests, handle authorization
    return this.addAuthorization(request).pipe(
      switchMap((authorizedRequest) => next.handle(authorizedRequest)),
      catchError((error) => {
        // return next.handle(request); // Proceed with the original request on failure
        return throwError(error);
      })
    );
  }

  /**
   * Adds the Authorization header to the request.
   * If the access token is expired, it tries to refresh it using the refresh token.
   *
   * @private
   * @param {HttpRequest<any>} request - The original HTTP request.
   * @returns {Observable<HttpRequest<any>>} - An observable containing the modified HTTP request.
   */
  private addAuthorization(
    request: HttpRequest<any>
  ): Observable<HttpRequest<any>> {
    if (this.authService.isAccessTokenValid()) {
      // Access token is valid, clone request with token

      return from(this.getTokenForBaseUrl(request.url)).pipe(
        switchMap((resolvedToken) => {
          const token = resolvedToken;
          return of(this.cloneRequestWithToken(request, token));
        })
      );
    }

    // Access token is invalid or expired, attempt refresh
    // if (this.authService.isRefreshTokenValid()) {
    //   return from(this.authService.refreshAccessToken()).pipe(
    //     switchMap(() => {
    //       const newToken = this.getTokenForBaseUrl(request.url);
    //       return of(this.cloneRequestWithToken(request, newToken));
    //     }),
    //     catchError((err) => {
    //       console.error("Token refresh failed", err); // Optional: remove in production
    //       this.authService.logout(); // Logout on refresh failure
    //       return of(request); // Return original request on failure
    //     })
    //   );
    // }
    else {
      // Both access and refresh tokens are invalid, logout
      this.authService.logout();
      return of(request);
    }
  }

  /**
   * Determines which token (access or client) to use based on the request URL.
   *
   * @private
   * @param {string} requestUrl - The request URL.
   * @returns {string | null} - The appropriate token (client or access), or null if not available.
   */
  private async getTokenForBaseUrl(requestUrl: string): Promise<string | null> {
    const clientUrls = this.configService.getUrlsToAddClientToken();

    const useClientToken = clientUrls.some((url) => requestUrl.includes(url));

    if (useClientToken) {
      if (!this.authService.getClientAuthToken()) {
        const params = new HttpParams()
          .set("client_id", environment.clientId)
          .set("client_secret", environment.clientSecret)
          .set("grant_type", "client_credentials");
        try {
          await this.authService.setKeycloakToken(params).toPromise();
          return this.authService.getClientAuthToken();
        } catch (e) {}
      }
      return this.authService.getClientAuthToken();
    }

    return this.authService.getToken() ?? null;
  }

  /**
   * Clones the original request and adds the Authorization header with the token.
   *
   * @private
   * @param {HttpRequest<any>} original - The original HTTP request.
   * @param {string} token - The authorization token.
   * @returns {HttpRequest<any>} - The modified HTTP request with the token.
   */
  private cloneRequestWithToken(
    original: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return original.clone({
      headers: original.headers
        .set("Authorization", `Bearer ${token}`)
        .set("Content-Type", "application/json; charset=utf-8"),
    });
  }
}

// refres token logic
// return next.handle(request).pipe(
//   catchError((error: HttpErrorResponse) => {
//     if (error && error.status === 401) {
//       // Token expired or unauthorized, try to refresh the token
//       return this.authService.refresh().pipe(
//         switchMap((response) => {
//           localStorage.setItem('accessToken', response.accessToken);
//           request = this.addToken(request, response.accessToken);
//           return next.handle(request);
//         }),
//         catchError((refreshError) => {
//           // Refresh token failed, redirect to login
//           this.authService.logout();
//           return throwError(refreshError);
//         })
//       );
//     }

//     return throwError(error);
//   })
// );
