import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import Swal from "sweetalert2";
import { ConfigService } from "../config.service";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  public defaultStartPage: string;
  // private sharedMenuDataSubject = new Subject<any[]>();
  private sharedMenuDataSubject = new BehaviorSubject<any[]>([]);

  public sharedMenuData$ = this.sharedMenuDataSubject.asObservable();

  public setSharedMenuData(data: any[]): void {
    this.sharedMenuDataSubject.next(data);
  }

  constructor(
    private appService: AppService,
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {}

  provideLinkMenuToRole(roleId) {
    const params = new HttpParams().set("roleId", roleId ?? "");
    return this.httpClient
      .get(`/UserService/provideLinkMenuToRole`, { params })
      .map((res) => res)
      .catch(this.errorHandler);
  }

  errorHandler(error) {
    let errorMessage = JSON.parse(error._body);
    Swal.fire({
      title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
      html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
      showCloseButton: true,
      showConfirmButton: false,
      showCancelButton: false,
      focusConfirm: true,
      icon: "error",
    });
    return Observable.throw(error || " server error ");
  }
}
