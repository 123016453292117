import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayModule } from "@angular/cdk/overlay";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from "ng-click-outside";

import { AutocompleteModule } from "./components/autocomplete/autocomplete.module";
import { PipeModule } from "app/shared/pipes/pipe.module";
import { NgxColorsModule } from "ngx-colors";

//COMPONENTS
import { FooterComponent } from "../core/footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { VerticalMenuComponent } from "./vertical-menu/vertical-menu.component";
import { CustomizerComponent } from "./customizer/customizer.component";
import { NotificationSidebarComponent } from "./notification-sidebar/notification-sidebar.component";
import { InfiniteScrollComponent } from "./components/infinite-scroll/infinite-scroll.component";

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarLinkDirective } from "./directives/sidebar-link.directive";
import { SidebarDropdownDirective } from "./directives/sidebar-dropdown.directive";
import { SidebarAnchorToggleDirective } from "./directives/sidebar-anchor-toggle.directive";
import { SidebarDirective } from "./directives/sidebar.directive";
import { TopMenuDirective } from "./directives/topmenu.directive";
import { TopMenuLinkDirective } from "./directives/topmenu-link.directive";
import { TopMenuDropdownDirective } from "./directives/topmenu-dropdown.directive";
import { TopMenuAnchorToggleDirective } from "./directives/topmenu-anchor-toggle.directive";
import { FileSizePipe } from "./pipes/filesize.pipe";
import { MatStepperModule } from "@angular/material/stepper";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";

import { MatTooltipModule } from "@angular/material/tooltip";
import { MatInputModule } from "@angular/material/input";
import { UserService } from "./services/user.service";
import { LoginService } from "./services/login.service";
import { SharedService } from "./shared.service";
import { CustomDropdownComponent } from "./components/custom-dropdown/custom-dropdown.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb/breadcrumb.component";
import { MenuComponent } from "./menu/menu/menu.component";
import { KeyvaluePipe } from "./pipes/keyvalue.pipe";
import { TokenMaskDirective } from "./directives/token-mask-directive/token-mask.directive";
import { CopyToClipboardDirective } from "./directives/copy-to-clipboard/copy-to-clipboard.directive";
import { ImageLazyLoadDirective } from "./directives/image-lazy-load/image-lazy-load.directive";
import { HorizontalMenuComponent } from "./horizontal-menu/horizontal-menu.component";
import { DirectiveModule } from "app/modules/directive/directive.module";

@NgModule({
  exports: [
    CommonModule,
    MenuComponent,
    // FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    InfiniteScrollComponent,
    CustomDropdownComponent,
    CustomizerComponent,
    BreadcrumbComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    TopMenuDirective,
    NgbModule,
    TranslateModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    MatInputModule,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    AutocompleteModule,
    PipeModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    MatInputModule,
    NgxColorsModule,
    // DirectiveModule
  ],
  declarations: [
    // FooterComponent,
    MenuComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    TopMenuLinkDirective,
    TopMenuDropdownDirective,
    TopMenuAnchorToggleDirective,
    TopMenuDirective,
    InfiniteScrollComponent,
    CustomDropdownComponent,
    BreadcrumbComponent,
  ],
  providers: [
    SharedService,
    FileSizePipe,
    UserService,
    LoginService,
    KeyvaluePipe,
  ],
})
export class SharedModule {}
