import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import Swal from "sweetalert2";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SharedService {
  public editUserDetails: any;
  public access_token: any;
  public editUserID: any;
  public userFormTamplateId: any;
  public access_token_asw: any;

  constructor(private httpClient: HttpClient) {}

  getUserInfo() {
    return this.httpClient
      .get(`/LoginService/users/root`, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => {
        return { users: res["data"]["users"] };
      });
  }

  getLogoUrl(url) {
    return this.httpClient.get(url, { responseType: "blob" }).map((res) => res);
  }

  errorHandler(error) {
    let errorMessage = JSON.parse(error._body);

    if (errorMessage.status == 400) {
      Swal.fire("Sorry!", errorMessage.message, "error");
    } else if (errorMessage.status == 404) {
      Swal.fire("Sorry!", errorMessage.message, "error");
    } else if (errorMessage.status == 401) {
      Swal.fire("Sorry!", errorMessage.message, "error");
    } else if (
      errorMessage.message == "Access is Denied" ||
      errorMessage.message == "Access is denied"
    ) {
      Swal.fire("Sorry!", errorMessage.message, "error");
    }

    return Observable.throw(error || " server error ");
  }
}
