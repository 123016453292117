import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyvalue'
})
export class KeyvaluePipe implements PipeTransform {

  transform(value, args: string[]): any {
    let keys = [];
    console.log(value)
    for (let key in value) {
      keys.push({ key: key, value: value[key] });
    }
    console.log(keys)
    return keys;
  }

}
