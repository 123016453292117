import { inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";

@Injectable()
export class ChatbotScriptService {
  private renderer: Renderer2;
  private scriptElement: HTMLScriptElement | null = null; // Store reference to the script element

  rendererFactory: RendererFactory2 = inject(RendererFactory2);

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  loadChatbotScript(
    scriptUrl: string,
    attributes: { [key: string]: string } = {}
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!document.querySelector(`script[src="${scriptUrl}"]`)) {
        this.scriptElement = this.renderer.createElement("script");

        this.scriptElement.src = scriptUrl;

        // Set attributes for the script
        Object.keys(attributes).forEach((key) => {
          this.renderer.setAttribute(this.scriptElement, key, attributes[key]);
        });

        // Event listeners for loading
        this.renderer.listen(this.scriptElement, "load", () => {
          resolve();
        });
        this.renderer.listen(this.scriptElement, "error", () =>
          reject(`Failed to load Chatbase script: ${attributes.src}`)
        );

        // Append the script to the body
        this.renderer.appendChild(document.body, this.scriptElement);
      } else {
        resolve(); // Script is already loaded
      }
    });
  }

  unloadChatbot() {
    if (this.scriptElement) {
      console.log(this.scriptElement);
      // Remove the script element using Renderer2
      this.renderer.removeChild(document.body, this.scriptElement);
      this.scriptElement = null; // Clear the reference
      console.log("Chatbase script unloaded");
    }
  }
}
