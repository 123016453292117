import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import { ROUTES } from "./vertical-menu-routes.config";
import { HROUTES } from "../horizontal-menu/HROUTES";

import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { LayoutService } from "../services/layout.service";
import { ProfileService } from "app/modules/profiles/services/profile.service";
import { SharedService } from "../shared.service";
import { AppService } from "app/app.service";
import { LoginService } from "../services/login.service";
import { CustomTitleStrategyService } from "../services/custom-title-strategy.service";
import { ThemeService } from "../services/theme.service";
import { AppInitializerService } from "app/core/initializer/services/app-initializer.service";
import { UserService } from "../services/user.service";
import { MenuService } from "../services/menu-service/menu.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("toggleIcon") toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = "assets/img/smallogo.png";
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  public imageUrl;

  public entityName: string = "";

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private loginService: LoginService,
    private profileService: ProfileService,
    private sharedService: SharedService,
    private appService: AppService,
    private customTitleService: CustomTitleStrategyService,
    private themeService: ThemeService,
    public initializeService: AppInitializerService,
    private userService: UserService,
    private menuService: MenuService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  ngOnInit() {
    this.loginService.access_token = localStorage.getItem("access_token");
    this.filterMenus(ROUTES, "vertical");
    this.init();
  }

  filterMenus(menuList, position: string) {
    this.menuService.sharedMenuData$.subscribe((menus) => {
      const sortedMenus = menus.sort(
        (a, b) => parseInt(a.sequence) - parseInt(b.sequence)
      );

      const filterMenusByPosition = (menuArray) => {
        return menuArray.filter((menu) =>
          sortedMenus.some((m) => m.menuName === menu.title)
        );
      };

      if (menus.length > 0) {
        this.menuItems = filterMenusByPosition(menuList);
      } else {
        if (position === "vertical") {
          const menus = filterMenusByPosition(ROUTES);
          this.menuItems = menus.length > 0 ? menus : ROUTES;
        } else if (position === "horizontal") {
          const menus = filterMenusByPosition(HROUTES);
          this.menuItems = menus.length > 0 ? menus : HROUTES;
        }
      }

      this.cdr.detectChanges();
    });
  }

  init() {}

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      (collapse) => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      }
    );
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(
      (() => {
        this.innerWidth = event.target.innerWidth;
        this.loadLayout();
      }).bind(this),
      500
    );
  }

  loadLayout() {
    if (this.config.layout.menuPosition === "Top") {
      // Horizontal Menu
      if (this.innerWidth < 1200) {
        // Screen size < 1200
        // this.menuItems = HROUTES
        this.filterMenus(ROUTES, "horizontal");
        this.cdr.detectChanges();
      }
    } else if (this.config.layout.menuPosition === "Side") {
      // Vertical Menu{
      this.filterMenus(ROUTES, "vertical");
      // this.menuItems = ROUTES
      this.cdr.detectChanges();
    }

    if (this.config.layout.sidebar.backgroundColor === "white") {
      // this.logoUrl = "assets/img/logo-dark.png";
      this.logoUrl = "assets/img/smalllogo.png";
    } else {
      // this.logoUrl = "assets/img/logo.png";
      this.logoUrl = "assets/img/smalllogo.png";
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }
}
