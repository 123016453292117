import { Router, ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";
import { LoginService } from "../../shared/services/login.service";
import { environment } from "environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { HttpService } from "../services/http.service";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { KeycloakService } from "../services/keycloak-service/keycloak.service";

@Injectable()
export class AuthService {
  private token: string;

  private clientToken: string;

  private accessTokenKey = "access_token";
  private refreshTokenKey = "refresh_token";
  private accessTokenExpiryKey = "access_token_expiry";
  private refreshTokenExpiryKey = "refresh_token_expiry";

  private clientAccessToken = "client_access_token";

  constructor(
    public activateRoute: ActivatedRoute,
    public router: Router,
    public loginService: LoginService,
    private http: HttpClient,
    private httpService: HttpService,
    private keycloakService: KeycloakService
  ) {
    this.monitorLocalStorage();
  }

  getToken() {
    return this.token;
  }

  /**
   * to append access token in header.
   */
  setAuthToken(token) {
    this.token = token;
  }

  /**
   * to append access token in header.
   */
  private setClientAuthToken(token) {
    localStorage.setItem("client_access_token", token);
    this.clientToken = token;
  }

  public getClientAuthToken(): string {
    return localStorage.getItem("client_access_token");
  }

  isAuthenticated() {
    if (!!this.getToken()) {
      return true;
    } else {
      this.router.navigate(["/pages/login"]);
      return false; // not allowed to enter without credential
    }
  }

  public parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = decodeURIComponent(
      window
        .atob(base64Url)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(base64);
  }

  public generateRandomString(length: number): string {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }
    return result;
  }

  setKeycloakToken(body: HttpParams) {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this.httpService.httpWithoutInterceptor
      .post<any>(
        `${environment.keycloakUrl}/realms/${environment.realm}/protocol/openid-connect/token`,
        body,
        { headers }
      )
      .pipe(
        map((res) => {
          // this.setClientAuthToken(res.access_token);
          this.saveTokens(res);
          return res;
        }),
        catchError(this.errorHandler)
      );
  }

  OauthDetails(): Promise<any> {
    return this.http
      .get(`/UserService/oauthDetails`, {
        observe: "body",
        responseType: "json",
      })
      .map((res) => res)
      .toPromise();
  }

  // Save tokens and expiry times in local storage
  saveTokens(tokenData: any) {
    if (!tokenData.refresh_token)
      this.setClientAuthToken(tokenData.access_token);

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const accessTokenExpiryTime = currentTime + tokenData.expires_in;
    localStorage.setItem(this.accessTokenKey, tokenData.access_token);
    localStorage.setItem(
      this.accessTokenExpiryKey,
      accessTokenExpiryTime.toString()
    );

    if (tokenData.refresh_token) {
      this.setAuthToken(tokenData.access_token);
      const refreshTokenExpiryTime = currentTime + tokenData.refresh_expires_in;
      localStorage.setItem(this.refreshTokenKey, tokenData.refresh_token);
      localStorage.setItem(
        this.refreshTokenExpiryKey,
        refreshTokenExpiryTime.toString()
      );
    }
  }

  getAccessToken(): string | null {
    return localStorage.getItem(this.accessTokenKey);
  }

  isAccessTokenValid(): boolean {
    const token = this.getAccessToken();
    if (!token) {
      this.handleMissingToken(); // Handle missing token scenario
      return false;
    }
    const expiryTime = parseInt(
      localStorage.getItem(this.accessTokenExpiryKey) || "0",
      10
    );
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return currentTime < expiryTime;
  }

  isRefreshTokenValid(): boolean {
    const token = this.getRefreshToken();
    if (!token) {
      this.handleMissingToken(); // Handle missing token scenario
      return false;
    }
    const expiryTime = parseInt(
      localStorage.getItem(this.refreshTokenExpiryKey) || "0",
      10
    );
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return currentTime < expiryTime;
  }

  // Retrieve refresh token
  private getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  refreshAccessToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();

    return this.http
      .post("/api/refresh-token", { refresh_token: refreshToken })
      .pipe(
        tap((newTokenData: any) => {
          this.saveTokens(newTokenData);
        }),
        catchError((error) => {
          this.handleRefreshTokenFailure(); // Handle failure
          return throwError(error);
        })
      );
  }

  private handleRefreshTokenFailure() {
    this.logout();
    // Optionally, display a message to the user
  }

  private handleMissingToken() {
    this.logout(); // Log out the user or redirect to login page
    // Optionally, show a message to the user
    // alert("Session data is missing. Please log in again.");
  }

  // Logout by clearing tokens and redirecting to login
  async logout() {
    try {
      const body = new HttpParams()
        .set("client_id", environment.clientId)
        .set("client_secret", environment.clientSecret)
        .set("grant_type", "client_credentials");

      // Set Keycloak token
      await this.setKeycloakToken(body).toPromise();

      // Logout session
      await this.keycloakService
        .logoutKeycloakSession(this.loginService.userId)
        .toPromise();

      // Clear tokens and localStorage in a loop
      const tokenKeys = [
        this.accessTokenKey,
        this.refreshTokenKey,
        this.accessTokenExpiryKey,
        this.refreshTokenExpiryKey,
        this.clientAccessToken,
      ];

      tokenKeys.forEach((key) => localStorage.removeItem(key));

      // Redirect to the login page
      window.open("/", "_self");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  monitorLocalStorage() {
    window.addEventListener("storage", (event) => {
      const accessToken = localStorage.getItem(this.accessTokenKey);
      const accessTokenExpiry = localStorage.getItem(this.accessTokenExpiryKey);

      // If tokens are missing or expired, log out the user
      if (!accessToken || !accessTokenExpiry) {
        this.handleMissingToken();
      }
    });
  }

  errorHandler(error: Response) {
    if (error.status == 500) {
      alert("Invalid RootUser/UserName/Password");
    }
    return throwError(error || " server error ");
  }
}
